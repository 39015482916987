import React from "react";

const Footer = () => {
    return (
      <footer className="footer">
        <p className="footer__copyright">&copy; Alrededor de los EEUU</p>
      </footer>
    );
}

export default Footer;